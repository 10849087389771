import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import CloseIcon from '@material-ui/icons/Close';

import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';

import Copy from '../Copy/Copy';

import { vote_coupon } from '../../apis/Qasimati';

import useStyles from './styles';

const CouponModal = ({ open, setOpen, coupon, lng, handleLikedCoupon }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [date, setDate] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setValid(false);
    setInvalid(false);
    setDate(null);
  };

  const handleValid = async () => {
    setValid(true);
    setInvalid(false);
    try {
      const { data } = await vote_coupon({
        id: coupon.id,
        type: 'enable',
        lng,
      });
      setDate(data.enable_time);
      handleLikedCoupon(coupon.id, data.enable_time);
    } catch (err) {}
  };

  const handleInvalid = async () => {
    setInvalid(true);
    setValid(false);
    try {
      await vote_coupon({ id: coupon.id, type: 'disable' });
    } catch (err) {}
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide direction='down' in={open}>
        <Container className={classes.container}>
          <div className={classes.paper}>
            <Button
              className={classes.close}
              color='primary'
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
            <div className={classes.divide} />
            <div className={classes.details}>
              <Avatar
                src={coupon.store.image}
                className={classes.avatar}
                imgProps={{ style: { objectFit: 'contain' } }}
              />
              <Typography variant='h5' className={classes.title} gutterBottom>
                {coupon.store[`name`]}
              </Typography>
              <Link
                href={coupon?.link}
                target='_blank'
                className={classes.link}
              >
                <Chip
                  label={t('visit_website')}
                  deleteIcon={
                    <LinkOutlinedIcon color='primary' fontSize='small' />
                  }
                  size='small'
                  onDelete={() => {}}
                  variant='outlined'
                  color='primary'
                  className={classes.chip}
                />
              </Link>
              <Divider className={classes.divider} />
              <div className={classes.info}>
                <Typography variant='h6' className={classes.title2}>
                  {t('offer')}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.value}
                  gutterBottom
                >
                  {coupon[`main_title`]}
                </Typography>
                <Typography variant='h6' className={classes.title2}>
                  {t('details')}
                </Typography>
                <Typography
                  variant='body2'
                  className={classes.value}
                  gutterBottom
                >
                  {coupon['description']}
                </Typography>
                <Typography
                  variant='h6'
                  className={classes.title2}
                  gutterBottom
                >
                  {t('last_valid_use')}:{' '}
                  <span color='primary' className={classes.span}>
                    {date ? date : coupon.enable}
                  </span>
                </Typography>

                <Copy code={coupon.code} />
              </div>
              <Divider className={classes.divider} />
              <div className={classes.likeActions}>
                <Typography variant='h6' className={classes.title2}>
                  {t('is_coupon_work')}
                </Typography>
                <div className={classes.likesContainer}>
                  <Tooltip title={t('valid')} placement='bottom'>
                    <IconButton
                      className={classes.likeActionIcon}
                      onClick={handleValid}
                    >
                      {valid ? (
                        <ThumbUpAltIcon color='primary' />
                      ) : (
                        <ThumbUpAltOutlinedIcon color='primary' />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('invalid')} placement='bottom'>
                    <IconButton
                      className={classes.likeActionIcon}
                      onClick={handleInvalid}
                    >
                      {invalid ? (
                        <ThumbDownAltIcon color='primary' />
                      ) : (
                        <ThumbDownAltOutlinedIcon color='primary' />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Slide>
    </Modal>
  );
};
export default CouponModal;
