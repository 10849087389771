import Container from '@material-ui/core/Container';

import useStyles from './styles';

const CustomContainer = ({ children }) => {
  const classes = useStyles();

  return <Container className={classes.root}>{children}</Container>;
};

export default CustomContainer;
