import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  code: {
    border: '1px dashed',
    borderRight: 0,
    borderColor: theme.palette.primary.main,
    padding: 10,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  copy: {
    background: theme.palette.primary.main,
    color: '#fff',
    padding: 10,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  success: {
    position: 'absolute',
    right: 5,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  icon: {
    position: 'relative',
    top: -2,
  },
  btn: {
    padding: 10,
  },
}));
