import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    background: '#f2f2f2',
    marginTop: 30,
    padding: 5,
  },
  slide: {
    width: '150px!important',
    height: 85,
    cursor: 'pointer',
  },
  wrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
  },
  allStoresLink: {
    display: 'block',
    height: '100%',
    textDecoration: 'none',
    color: '#333',
  },
  allStores: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    background: '#fff',
  },
}));
