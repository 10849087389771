import { Route, Redirect } from 'react-router-dom';

const VerifiedRoute = ({ children, ...rest }) => {
  const is_verify = JSON.parse(localStorage.getItem('user'))?.verified_at;

  return is_verify ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};
export default VerifiedRoute;
