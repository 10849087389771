import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { create } from 'jss';
import rtl from 'jss-rtl';

import { change_lang } from './redux/actions/lang';

import {
  MuiThemeProvider,
  StylesProvider,
  createTheme,
  jssPreset,
} from '@material-ui/core/styles';

function withRoot(Component) {
  function WithRoot(props) {
    // JssProvider allows customizing the JSS styling solution.
    const { i18n } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const urlLang = queryString.parse(location.search)?.lang;

    if (urlLang) {
      i18n.changeLanguage(urlLang);
      document.body.dir = i18n.dir();
      theme.direction = i18n.dir();
      document.getElementsByTagName('html')[0].setAttribute('lang', urlLang);
      dispatch(change_lang(urlLang));
    }

    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider
          theme={createTheme({
            direction: i18n.dir(),
            palette: {
              primary: {
                main: '#6057a1',
                contrastText: '#fff',
                100: 'rgba(96, 87, 161, 0.3)',
              },
            },
            typography: {
              fontFamily: ['Cairo', 'sans-serif'].join(','),

              //   h1: { fontSize: '3rem' },
              //   h2: { fontSize: '2.5rem' },
              h3: {
                fontSize: '2rem',
              },
              //   h4: { fontSize: '1.5rem' },
              //   h5: { fontSize: '1rem', fontWeight: 500 },
              h6: { fontSize: '.9rem', fontWeight: 600 },

              body2: {
                fontSize: '0.9rem',
                fontWeight: 500,
              },
            },
          })}
        >
          <Component {...props} />
        </MuiThemeProvider>
      </StylesProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
