import { Route, Redirect } from 'react-router-dom';
import { isAuthorized } from '../../utils/authorization';

const PrivateRoute = ({ children, ...rest }) => {
  return isAuthorized() ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};
export default PrivateRoute;
