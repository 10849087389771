import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  avatar: {
    width: 120,
    height: 120,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  popular_stores: {
    marginBottom: 50,
  },
}));
