import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {},
  item: {
    padding: '1px 20px',
    borderRadius: 20,
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 15,
    display: 'inline-flex',
    boxSizing: 'border-box',
  },
  slide: {
    width: 'auto!important',
  },
  active: {
    background: '#e7f1f8',
  },
  filter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    display: 'inline-block',
    marginInlineEnd: 8,
    color: '#333',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconImg: {
    marginInlineEnd: 8,
    width: 18,
  },
  check: {
    minWidth: 35,
  },
  last: {
    padding: '0px 200px',
  },
  filterContianer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterIconBtn: {
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  filterSwiper: {
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '93%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '95%',
    },
  },
  filterBtn: {
    [theme.breakpoints.up('xs')]: {
      width: '10%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '7%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '5%',
    },
  },
}));
