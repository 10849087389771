import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  check: {
    minWidth: 35,
  },
  img: {
    width: 20,
  },
}));
