import axios from 'axios';

const baseURL = 'https://qasimati.com/admin/api';
axios.interceptors.request.use((req) => {
  if (localStorage.getItem('user')) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem('user'))?.token
    }`;
  }
  return req;
});

// Authentication
export const register = (data) => axios.post(`${baseURL}/register`, data);

export const signin = (credentials) =>
  axios.post(`${baseURL}/login`, credentials);

export const get_authenticated_user = () => axios.get(`${baseURL}/user`);

export const verify_email = () =>
  axios.post(`${baseURL}/email/verification-notification`);

export const get_favorite = ({ page, lng }) =>
  axios.get(`${baseURL}/user/${lng}/favorite/coupon?page=${page}`);

export const add_to_favorite = (id) =>
  axios.post(`${baseURL}/user/add/favorite/coupon`, { coupon_id: id });

export const remove_from_favorite = (id) =>
  axios.delete(`${baseURL}/user/remove/favorite/coupon/${id}`);

export const edit_profile = (data) =>
  axios.post(`${baseURL}/edit/profile`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const forget_password = ({ email, lng }) =>
  axios.post(`${baseURL}/forgot-password`, { email, lang: lng });

// Countries & slider
export const get_countries = (lng) => axios.get(`${baseURL}/${lng}/countries`);

export const get_slider = (country) =>
  axios.get(`${baseURL}/${country}/countries/sliders`);

// Coupons
export const get_coupons = ({ page = 1, country, lng, keyword = '' }) =>
  axios.get(
    `${baseURL}/coupons/${country}-${lng}?page=${page}&se=${keyword}&user_id=${
      JSON.parse(localStorage.getItem('user'))?.id
    }`
  );

export const get_coupons_by_category = ({ category, country, lng, type }) =>
  axios.get(`${baseURL}/categories/${category}/coupons/${country}-${lng}`, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      type,
    },
  });

export const inc_visited_coupon = (id) =>
  axios.post(`${baseURL}/coupons/search`, { coupon_id: id });

export const vote_coupon = ({ id, type, lng }) =>
  axios.post(`${baseURL}/coupons/vote`, {
    coupon_id: id,
    type_vote: type,
    lang: lng,
  });

export const coupon_filteration = ({ country, lng }) =>
  axios.get(
    `${baseURL}/coupon/filtration/${country}-${lng}?user_id=${
      JSON.parse(localStorage.getItem('user'))?.id
    }`
  );

export const offer_filteration = ({ country, lng }) =>
  axios.get(
    `${baseURL}/offer/filtration/${country}-${lng}?user_id=${
      JSON.parse(localStorage.getItem('user'))?.id
    }`
  );

export const add_coupon = (data) =>
  axios.post(`${baseURL}/coupons/create`, data);

// Categories
export const get_categories = (lng) =>
  axios.get(`${baseURL}/${lng}/categories`);

// Stores
export const get_stores_home = ({ country, lng }) =>
  axios.get(`${baseURL}/stores/inHome/${country}-${lng}`);

export const get_store_coupons = ({ store, country, lng }) =>
  axios.get(
    `${baseURL}/stores/${store}/coupons/${country}-${lng}?user_id=${
      JSON.parse(localStorage.getItem('user'))?.id
    }`
  );

export const get_feature_stores = ({ country, lng }) =>
  axios.get(`${baseURL}/stores/feature/${country}-${lng}`);

export const get_stores = ({ page, country, lng, keyword = '' }) =>
  axios.get(`${baseURL}/stores/${country}-${lng}?page=${page}&se=${keyword}`);

// Contact us
export const contact_us = (data) => axios.post(`${baseURL}/page/contact`, data);

// Socail Media
export const get_social_links = () => axios.get(`${baseURL}/social`);

// Common Questions
export const get_common_questions = (lng) =>
  axios.get(`${baseURL}/${lng}/questions`);

// About Us
export const about_us = (lng) => axios.get(`${baseURL}/page/${lng}/whoAreWe`);

// Privacy Policy
export const privacy_policy = (lng) =>
  axios.get(`${baseURL}/page/${lng}/policies`);

// Terms
export const terms = (lng) => axios.get(`${baseURL}/page/${lng}/terms`);
