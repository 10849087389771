import { Pagination } from '@material-ui/lab';

import useStyles from './styles';

const Paginate = ({ page, pages, setPage }) => {
  const classes = useStyles();

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className={classes.paginate}>
      <Pagination
        count={pages}
        pages={page}
        color='primary'
        variant='outlined'
        size='large'
        onChange={handleChange}
      />
    </div>
  );
};

export default Paginate;
