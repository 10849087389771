import {
  AUTH_REQUEST,
  SIGNUP,
  LOGIN,
  LOGOUT,
  AUTH_FAILURE,
  GET_AUTHENTICATED_USER,
  CLEANUP,
  EDIT_PROFILE,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_CLEANUP,
} from '../constants/auth';

const intialState = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : {};

const authReducer = (state = intialState, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return { ...state, loading: true };

    case SIGNUP:
      return { ...action.payload, loading: false, success: true };

    case LOGIN:
      return { ...action.payload, loading: false, success: true };

    case EDIT_PROFILE:
      return {
        ...state,
        ...action.payload,
        profile_success: 'true',
        loading: false,
      };

    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        profile_success: 'false',
      };

    case EDIT_PROFILE_CLEANUP:
      return {
        ...state,
        profile_success: null,
      };

    case GET_AUTHENTICATED_USER:
      return { ...action.payload, loading: false, success: true };

    case AUTH_FAILURE:
      return { error: action.payload, loading: false, success: false };

    case CLEANUP:
      return {
        ...state,
        error: null,
        loading: false,
        success: false,
      };

    case LOGOUT:
      return {};

    default:
      return state;
  }
};

export default authReducer;
