import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Heading from '../../components/Heading/Heading';
import ErrorInput from '../../components/ErrorInput/ErrorInput';

import { signup, cleanUp } from '../../redux/actions/auth';

import useStyles from './styles';
import { Helmet } from 'react-helmet';

const Signup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('name_required')),
      email: Yup.string()
        .required(t('email_required'))
        .email(t('email_invalid')),
      password: Yup.string()
        .required(t('password_required'))
        .min(6, t('password_invalid')),
    }),
    validateOnChange: false,
    onSubmit: (values) => {
      dispatch(
        signup({
          name: values.name,
          email: values.email,
          password: values.password,
        })
      );
    },
  });

  useEffect(() => {
    return () => dispatch(cleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (user?.name) {
      history.push('/');
    }
  }, [history, user]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('signup')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('signup')} />

        <div className={classes.switcher}>
          <Typography variant='h6'>
            {t('have_account')}
            <Link to='/login' className={classes.link}>
              {t('signin')}
            </Link>
          </Typography>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('name')}
              name='name'
              variant='filled'
              size='small'
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {<ErrorInput text={formik.errors.name} />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('email')}
              name='email'
              variant='filled'
              size='small'
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {user?.error?.email ? (
              <ErrorInput text={t('email_used')} />
            ) : (
              <ErrorInput text={formik.errors.email} />
            )}
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('password')}
              name='password'
              variant='filled'
              size='small'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {<ErrorInput text={formik.errors.password} />}
          </FormControl>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={user?.loading}
          >
            {user?.loading ? t('wait') : t('signup')}
          </Button>
        </form>
      </div>
    </>
  );
};

export default Signup;
