import { combineReducers } from 'redux';

import auth from './auth';
import lang from './lang';
import country from './country';

export default combineReducers({
  user: auth,
  lang: lang,
  country: country,
});
