import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Heading from '../../components/Heading/Heading';
import ErrorInput from '../../components/ErrorInput/ErrorInput';

import { login, cleanUp } from '../../redux/actions/auth';

import useStyles from './styles';
import { Helmet } from 'react-helmet';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user?.name) {
      history.push('/');
    }
  }, [history, user]);

  useEffect(() => {
    return () => dispatch(cleanUp());
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(login({ email, password }));
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('login')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('signin')} />

        <div className={classes.switcher}>
          <Typography variant='h6'>
            {t('new_user')}{' '}
            <Link to='/signup' className={classes.link}>
              {t('signup')}
            </Link>
          </Typography>
        </div>

        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('email')}
            name='email'
            value={email}
            variant='filled'
            size='small'
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('password')}
            name='password'
            variant='filled'
            value={password}
            type='password'
            size='small'
            required
            onChange={(e) => setPassword(e.target.value)}
          />

          {user.error && <ErrorInput text={t('invalid_login')} />}
          <Link to='/forget-password' className={classes.link}>
            {t('forget_password')}
            {t('question_mark')}
          </Link>
        </FormControl>

        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          disabled={user?.loading}
        >
          {t('signin')}
        </Button>
      </div>
    </>
  );
};

export default Login;
