import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    background: '#f1c40f',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    color: '#333',
  },
  btn: {
    background: 'none',
    color: '#2980b9',
    textDecorationStyle: 'solid',
    cursor: 'pointer',
    paddingInlineStart: 5,
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
}));
