import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';

const CustomModal = ({ open, setOpen, text }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Slide direction='down' in={open}>
        <Container className={classes.container}>
          <div className={classes.paper}>
            <Button
              className={classes.close}
              color='primary'
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </Button>
            <div className={classes.divide} />
            <div className={classes.details}>{text}</div>
          </div>
        </Container>
      </Slide>
    </Modal>
  );
};
export default CustomModal;
