import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '50px 0px',
  },
  bar: {
    margin: '15px  0',
    position: 'relative',
    width: '100%',
    height: 5,
    background: '#f2f2f2',
    borderRadius: 8,
    '&::before': {
      content: '""',
      display: 'block',
      borderRadius: 8,
      background: theme.palette.primary.main,
      width: '20%',
      height: '100%',
      position: 'absolute',
      left: '0',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
  },
}));
