import Link from '@material-ui/core/Link';
import logo from '../../assets/images/logo.png';

import useStyles from './styles';

const Logo = ({ isFooter }) => {
  const classes = useStyles();

  return (
    <Link href='/' className={classes.root}>
      <img src={logo} alt='logo' className={classes.img} />
    </Link>
  );
};

export default Logo;
