import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import Heading from '../../components/Heading/Heading';
import Coupon from '../../components/Coupon/Coupon';
import CouponModal from '../../components/CouponModal/CouponModal';
import Loader from '../../components/Loader/Loader';

import { get_store_coupons } from '../../apis/Qasimati';

import useStyles from './styles';
import { Helmet } from 'react-helmet';
import CustomModal from '../../components/Modal/Modal';

const Store = () => {
  const classes = useStyles();
  const { store } = useParams();
  const { t } = useTranslation();

  const lng = useSelector((state) => state.lang);
  const country = useSelector((state) => state.country);

  const [coupons, setCoupons] = useState([]);
  const [storeName, setStoreName] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(null);
  const [openNote, setOpenNote] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchStoreCoupons = async () => {
      try {
        const { data } = await get_store_coupons({ store, country, lng });
        setStoreName(data.store.name);
        setCoupons(data.coupons);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchStoreCoupons();
  }, [store, country, lng]);

  const handleSelectedCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    setOpen(true);
  };

  const handleLikedCoupon = (id, enable) => {
    setCoupons(
      coupons.map((coupon) =>
        coupon.id === id ? { ...coupon, enable } : coupon
      )
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{`${t('title')}${storeName}`}</title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={storeName} />

        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12}>
              <Loader />
            </Grid>
          ) : (
            coupons.map((coupon) => (
              <Grid key={coupon.id} item xs={6} sm={6} md={4} lg={3}>
                <Coupon
                  setSelectedCoupon={handleSelectedCoupon}
                  coupon={coupon}
                  setOpenNote={setOpenNote}
                />
              </Grid>
            ))
          )}
        </Grid>
        {selectedCoupon && (
          <CouponModal
            open={open}
            setOpen={setOpen}
            coupon={selectedCoupon}
            lng={lng}
            handleLikedCoupon={handleLikedCoupon}
          />
        )}
      </div>

      <CustomModal
        open={openNote}
        setOpen={setOpenNote}
        text={t('add_fav_tooltip')}
      />
    </>
  );
};

export default Store;
