import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import Skeleton from '@material-ui/lab/Skeleton';

import { get_slider } from '../../apis/Qasimati';

import useStyles from './styles';

const ArabicSwiper = ({ slider }) => {
  const classes = useStyles();

  return (
    <Swiper
      navigation={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className={classes.root}
    >
      {slider.map((slide, index) => (
        <SwiperSlide key={index}>
          <a href={slide.link} target='_blank' rel='noreferrer'>
            <img className={classes.img} src={slide.image} alt='slider' />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const EnglishSwiper = ({ slider }) => {
  const classes = useStyles();

  return (
    <Swiper
      navigation={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className={classes.root}
    >
      {slider.map((slide, index) => (
        <SwiperSlide key={index}>
          <a href={slide.link}>
            <img className={classes.img} src={slide.image} alt='slider' />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const CustomSlider = () => {
  const classes = useStyles();

  SwiperCore.use([Autoplay, Navigation]);

  const { i18n } = useTranslation();
  const [slider, setSlider] = useState([]);
  const [loading, setLoading] = useState(false);

  const country = useSelector((state) => state.country);

  useEffect(() => {
    const fetchSlider = async () => {
      setLoading(true);
      try {
        const { data } = await get_slider(country);
        setSlider(data.sliders);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchSlider();
  }, [country]);

  return loading ? (
    <Skeleton animation='wave' className={classes.root} variant='rect' />
  ) : slider?.length === 0 ? null : i18n.dir() === 'rtl' ? (
    <ArabicSwiper slider={slider} />
  ) : (
    <EnglishSwiper slider={slider} />
  );
};
export default CustomSlider;
