import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import CheckIcon from '@material-ui/icons/Check';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListSharp';

import { get_categories } from '../../apis/Qasimati';

import '@fortawesome/fontawesome-free/js/brands.js';
import '@fortawesome/fontawesome-free/js/solid.js';
import '@fortawesome/fontawesome-free/js/regular.js';
import '@fortawesome/fontawesome-free/js/fontawesome.js';

import SwiperCore, { Pagination } from 'swiper';

import useStyles from './styles';

const ArabicSwiper = ({ children }) => {
  const { i18n } = useTranslation();

  const [paginate, setPaginate] = useState(false);

  SwiperCore.use([Pagination]);

  useEffect(() => {
    setTimeout(() => setPaginate(true), 2000);
  }, []);

  return (
    <Swiper dir={i18n.dir()} slidesPerView='auto' pagination={paginate}>
      {children}
    </Swiper>
  );
};

const EnglishSwiper = ({ children }) => {
  const { i18n } = useTranslation();

  const [paginate, setPaginate] = useState(false);

  SwiperCore.use([Pagination]);

  useEffect(() => {
    setTimeout(() => setPaginate(true), 2000);
  }, []);

  return (
    <Swiper dir={i18n.dir()} slidesPerView='auto' pagination={paginate}>
      {children}
    </Swiper>
  );
};

const Filter = ({ setSelectedCategory, type, setType, setPage }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const lng = useSelector((state) => state.lang);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await get_categories(lng);
        setCategories(data.data);
      } catch (err) {}
    };
    fetchCategories();
  }, [lng]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSlideClick = (selectedItemId, selectedItemName) => {
    setSelectedCategory(selectedItemName);
    setSelected(selectedItemId);
  };

  const handleClickFitler = (type) => {
    setType(type);
    setPage(1);
    handleClose();
  };

  const renderSlides = () => {
    return (
      <>
        <SwiperSlide
          onClick={() => handleSlideClick(0, '')}
          className={classes.slide}
        >
          <div>
            <span
              className={`${classes.item} ${selected === 0 && classes.active}`}
            >
              {t('all')}
            </span>
          </div>
        </SwiperSlide>

        {categories.map((category) => {
          return (
            <SwiperSlide
              key={category.id}
              onClick={() => handleSlideClick(category.id, category.name)}
              className={classes.slide}
            >
              <div>
                <span
                  className={`${classes.item} ${
                    selected === category.id && classes.active
                  }`}
                >
                  <div className={classes.itemIcon}>
                    {category?.icon ? (
                      <i className={`${category.icon} ${classes.icon}`}></i>
                    ) : (
                      <img
                        src={category.image}
                        alt='category'
                        className={classes.iconImg}
                      />
                    )}
                  </div>
                  <div className={classes.itemText}>{category['name']}</div>
                </span>
              </div>
            </SwiperSlide>
          );
        })}
        {/* <SwiperSlide className={classes.slide}>
          <div className={classes.last}></div>
        </SwiperSlide> */}
      </>
    );
  };

  return (
    <div className={`${classes.filterContianer} filter`}>
      <div className={classes.filterSwiper}>
        {i18n.dir() === 'rtl' ? (
          <ArabicSwiper>{renderSlides()}</ArabicSwiper>
        ) : (
          <EnglishSwiper>{renderSlides()}</EnglishSwiper>
        )}
      </div>
      <div className={classes.filterBtn}>
        <div className={classes.filter}>
          <IconButton
            color='primary'
            variant='outlined'
            style={{ paddingRight: 0, paddingLeft: 0 }}
            className={classes.filterIconBtn}
            onClick={handleClick}
          >
            <FilterListOutlinedIcon />
          </IconButton>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleClickFitler('all')}>
              <ListItemIcon className={classes.check}>
                {type === 'all' && (
                  <CheckIcon fontSize='small' color='primary' />
                )}
              </ListItemIcon>
              {t('all')}
            </MenuItem>
            <MenuItem onClick={() => handleClickFitler('offer')}>
              <ListItemIcon className={classes.check}>
                {type === 'offer' && (
                  <CheckIcon fontSize='small' color='primary' />
                )}
              </ListItemIcon>
              {t('filter_offer')}
            </MenuItem>
            <MenuItem onClick={() => handleClickFitler('coupon')}>
              <ListItemIcon className={classes.check}>
                {type === 'coupon' && (
                  <CheckIcon fontSize='small' color='primary' />
                )}
              </ListItemIcon>
              {t('filter_coupon')}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Filter;
