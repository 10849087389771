import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Heading from '../../components/Heading/Heading';
import ErrorInput from '../../components/ErrorInput/ErrorInput';

import { contact_us } from '../../apis/Qasimati';

import useStyles from './styles';
import SnackbarC from '../../components/SnackbarC/SnackbarC';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('contact_name_required')),
      email: Yup.string()
        .required(t('contact_email_required'))
        .email(t('email_invalid')),
      subject: Yup.string().required(t('contact_subject_required')),
      message: Yup.string().required(t('contact_message_required')),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await contact_us({
          name: values.name,
          email: values.email,
          title: values.subject,
          body: values.message,
        });
        if (response.status === 200) {
          resetForm({});
          setLoading(false);
          setOpen(true);
        }
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('contact_us')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('contact_us')} />

        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('contact_name')}
              variant='filled'
              size='small'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {<ErrorInput text={formik.errors.name} />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('contact_email')}
              variant='filled'
              size='small'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {<ErrorInput text={formik.errors.email} />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('contact_subject')}
              variant='filled'
              size='small'
              name='subject'
              value={formik.values.subject}
              onChange={formik.handleChange}
            />
            {<ErrorInput text={formik.errors.subjcet} />}
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('contact_message')}
              variant='filled'
              size='small'
              name='message'
              value={formik.values.message}
              onChange={formik.handleChange}
              multiline
              minRows={7}
            />
            {<ErrorInput text={formik.errors.message} />}
          </FormControl>

          <Button variant='text' onClick={() => history.push('/')}>
            {t('cancel')}
          </Button>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
          >
            {loading ? t('wait') : t('send')}
          </Button>
        </form>
        <SnackbarC
          open={open}
          setOpen={setOpen}
          message={t('message_sent_successfully')}
        />
      </div>
    </>
  );
};

export default ContactUs;
