import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    background: '#fff',
    borderRadius: 8,
    top: 'calc(100% + 5px)',
    zIndex: '999',
  },
  avatar: {
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    width: theme.spacing(7),
    height: theme.spacing(7),
    boxShadow: theme.shadows[3],
  },
}));
