import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const FileInput = ({ onImageChange, setImageURL, name, label, id }) => {
  const classes = useStyles();

  const uploadFileHandler = async (e) => {
    onImageChange(name, e.target.files[0]);
    setImageURL(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <TextField
      variant='outlined'
      label={label}
      type='file'
      name={name}
      id={id}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={uploadFileHandler}
      className={classes.root}
    />
  );
};

export default FileInput;
