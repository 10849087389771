import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  topFooter: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    paddingTop: 20,
  },

  container: {},
  copyright: {
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 1,
    fontSize: 18,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[100],
  },

  information: {},
  stores: {},

  social: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    listStyle: 'none',
  },
  socialIcon: {
    color: '#fff',
    marginInlineEnd: 30,
  },

  download: {
    width: '70%',
  },
  idenText: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '1.1rem',
    marginTop: 8,
  },
  addCoupon: {
    color: 'rgba(255, 255, 255, 0.7)!important',
    padding: 0,
    textTransform: 'capitalize',
    fontSize: '1rem',
    '&:hover': {
      background: 'none',
    },
  },
}));
