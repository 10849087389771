import jwt from 'jsonwebtoken';

export const isAuthorized = () => {
  try {
    const token = JSON.parse(localStorage.getItem('user'))?.token;
    const decodedToken = jwt.decode(token);
    const date = new Date();
    if (decodedToken?.exp < date.getTime()) return true;
    //   localStorage.removeItem('token');
    return false;
  } catch {
    //localStorage.removeItem('token');
    return false;
  }
};
