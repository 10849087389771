import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 50,
    minHeight: '60vh',
  },
  paper: {
    padding: 50,
  },
}));
