import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import useStyles from './styles';

const Copy = ({ code }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied === true) setTimeout(() => setCopied(false), 2000);
  }, [copied]);

  return (
    <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
      <div className={classes.root}>
        {copied ? (
          <Button
            startIcon={<CheckOutlinedIcon />}
            variant='contained'
            color='primary'
            className={classes.btn}
          >
            {t('copied')}
          </Button>
        ) : (
          <>
            <div className={classes.code}>
              <Typography variant='h6'>{code}</Typography>
            </div>
            <div className={classes.copy}>
              <Typography variant='h6'>{t('copy_code')}</Typography>
            </div>
          </>
        )}
      </div>
      {/* {copied && (
          <div className={classes.success}>
            <CheckOutlinedIcon color='primary' className={classes.icon} />
            Copied!
          </div>
        )} */}
    </CopyToClipboard>
  );
};
export default Copy;
