import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: 120,
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    display: 'flex',
    paddingInlineEnd: 10,
  },
  img: {
    width: '100%',
  },
}));
