import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const SearchResult = ({ stores, handleClickedItem }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <List component='nav' aria-label='secondary mailbox folders'>
        {stores.length === 0 ? (
          <ListItem>لايوجد نتائج بحث</ListItem>
        ) : (
          stores.map((store) => (
            <ListItem
              key={store.id}
              button
              onClick={() => handleClickedItem(store.name)}
            >
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Avatar
                    src={store.image}
                    className={classes.avatar}
                    imgProps={{ style: { objectFit: 'contain' } }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant='h6'>{store.name}</Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))
        )}
      </List>
    </Paper>
  );
};

export default SearchResult;
