import {
  AUTH_REQUEST,
  SIGNUP,
  LOGIN,
  AUTH_FAILURE,
  LOGOUT,
  GET_AUTHENTICATED_USER,
  EDIT_PROFILE,
  CLEANUP,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_CLEANUP,
} from '../constants/auth';

import {
  get_authenticated_user,
  register,
  signin,
  edit_profile,
  verify_email,
} from '../../apis/Qasimati';

export const signup = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });

    const { data } = await register(formData);

    dispatch({
      type: SIGNUP,
      payload: {
        id: data.data.id,
        name: data.data.name,
        email: data.data.email,
        image: data.data.image,
        verified_at: data.data?.verify_email,
        token: data.data.access_token,
      },
    });

    localStorage.setItem('user', JSON.stringify(getState().user));

    await verify_email();
  } catch (error) {
    dispatch({
      type: AUTH_FAILURE,
      payload: error?.response?.data?.errors,
    });
  }
};

export const login = (credentials) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });
    const { data } = await signin(credentials);

    dispatch({
      type: LOGIN,
      payload: {
        id: data.data.id,
        name: data.data.name,
        email: data.data.email,
        image: data.data.image,
        verified_at: data.data.verified_at,
        token: data.data.access_token,
      },
    });

    localStorage.setItem('user', JSON.stringify(getState().user));
  } catch (error) {
    dispatch({
      type: AUTH_FAILURE,
      payload: error,
    });
  }
};

export const editProfile = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });
    const { data } = await edit_profile(formData);

    dispatch({
      type: EDIT_PROFILE,
      payload: {
        name: data.data.name,
        image: data.data.image,
      },
    });

    localStorage.setItem('user', JSON.stringify(getState().user));
  } catch (error) {
    dispatch({
      type: EDIT_PROFILE_FAILURE,
      payload: error,
    });
  }
};

export const editProfileCleanup = () => async (dispatch) => {
  dispatch({
    type: EDIT_PROFILE_CLEANUP,
  });
};

export const getAuthenticatedUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUTH_REQUEST,
    });
    const { data } = await get_authenticated_user();
    dispatch({
      type: GET_AUTHENTICATED_USER,
      payload: {
        id: data.data.id,
        name: data.data.name,
        email: data.data.email,
        image: data.data.image,
        verified_at: data.data?.email_verified_at,
        token: getState().user.token,
      },
    });

    localStorage.setItem('user', JSON.stringify(getState().user));
  } catch (error) {
    dispatch({
      type: AUTH_FAILURE,
      payload: error,
    });
  }
};

export const cleanUp = () => async (dispatch) => {
  dispatch({
    type: CLEANUP,
  });
  localStorage.clear('user');
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  localStorage.clear('user');
};
