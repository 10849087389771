import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';

import { get_common_questions } from '../../apis/Qasimati';

import useStyles from './styles';

const CommonQuestions = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const lng = useSelector((state) => state.lang);

  const [FQAs, setFQAs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchFQAs = async () => {
      try {
        const { data } = await get_common_questions(lng);
        setFQAs(data.questions);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchFQAs();
  }, [lng]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('common_questions')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <section className={classes.root}>
        <Heading text={t('common_questions')} />
        {loading ? (
          <Loader />
        ) : (
          FQAs.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography className={classes.heading}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </section>
    </>
  );
};

export default CommonQuestions;
