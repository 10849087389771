import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from '@material-ui/lab/Skeleton';

import { get_stores_home } from '../../apis/Qasimati';

import useStyles from './styles';
import { Typography } from '@material-ui/core';

const ArabicSwiper = ({ children }) => {
  return (
    <Swiper slidesPerView='auto' spaceBetween={15}>
      {children}
    </Swiper>
  );
};

const EnglishSwiper = ({ children }) => {
  return (
    <Swiper slidesPerView='auto' spaceBetween={15}>
      {children}
    </Swiper>
  );
};

const Stores = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const lng = useSelector((state) => state.lang);
  const country = useSelector((state) => state.country);

  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStores = async () => {
      setLoading(true);

      try {
        const { data } = await get_stores_home({ country, lng });
        setStores(data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchStores();
  }, [country, lng]);

  const renderSlides = () => {
    return (
      <>
        <SwiperSlide className={classes.slide}>
          <Link to={`/stores`} className={classes.allStoresLink}>
            <div className={classes.wrap}>
              <div className={classes.allStores}>
                <Typography variant='h5'>{t('all_stores_all')}</Typography>
                <Typography variant='body1'>
                  {t('all_stores_stores')}
                </Typography>
              </div>
            </div>
          </Link>
        </SwiperSlide>
        {stores.map((store) => {
          return (
            <SwiperSlide key={store.id} className={classes.slide}>
              <Link to={`/stores/${store.name}`}>
                <div className={classes.wrap}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '85px' }}
                    src={store.image}
                    alt='stores'
                  />
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </>
    );
  };
  return loading ? (
    <Skeleton
      animation='wave'
      height={85}
      className={classes.root}
      variant='rect'
    />
  ) : (
    <div className={classes.root}>
      {i18n.dir() === 'rtl' ? (
        <ArabicSwiper>{renderSlides()}</ArabicSwiper>
      ) : (
        <EnglishSwiper>{renderSlides()}</EnglishSwiper>
      )}
    </div>
  );
};

export default Stores;
