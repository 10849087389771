import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import withRoot from './withRoot';

import VerifiedRoute from './components/VerifiedRoute/VerifiedRoute';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import Home from './pages/Home/Home';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import AddCoupon from './pages/AddCoupon/AddCoupon';
import Favorite from './pages/Favorite/Favorite';
import CommonQuestions from './pages/CommonQuestions/CommonQuestions';
import Stores from './pages/Stores/Stores';
import Store from './pages/Store/Store';
import Profile from './pages/Profile/Profile';
import ContactUs from './pages/ContactUs/ContactUs';
import AboutUs from './pages/AboutUs/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/Terms/Terms';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';

import { getAuthenticatedUser } from './redux/actions/auth';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('user'))?.token)
      dispatch(getAuthenticatedUser());
  }, [dispatch]);

  return (
    <Switch>
      <Route path='/' exact>
        <MainLayout>
          <Home />
        </MainLayout>
      </Route>

      <Route path='/login' exact>
        <MainLayout>
          <Login />
        </MainLayout>
      </Route>

      <Route path='/signup' exact>
        <MainLayout>
          <Signup />
        </MainLayout>
      </Route>

      <Route path='/fqa' exact>
        <MainLayout>
          <CommonQuestions />
        </MainLayout>
      </Route>

      <Route path='/stores' exact>
        <MainLayout>
          <Stores />
        </MainLayout>
      </Route>

      <Route path='/stores/:store' exact>
        <MainLayout>
          <Store />
        </MainLayout>
      </Route>

      <Route path='/contact-us' exact>
        <MainLayout>
          <ContactUs />
        </MainLayout>
      </Route>

      <Route path='/about-us' exact>
        <MainLayout>
          <AboutUs />
        </MainLayout>
      </Route>

      <Route path='/privacy-policy' exact>
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      </Route>

      <Route path='/terms-conditions' exact>
        <MainLayout>
          <Terms />
        </MainLayout>
      </Route>

      <Route path='/forget-password' exact>
        <MainLayout>
          <ForgetPassword />
        </MainLayout>
      </Route>

      <PrivateRoute path='/favorite' exact>
        <MainLayout>
          <Favorite />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute path='/profile' exact>
        <MainLayout>
          <Profile />
        </MainLayout>
      </PrivateRoute>

      <VerifiedRoute path='/add-coupon' exact>
        <MainLayout>
          <AddCoupon />
        </MainLayout>
      </VerifiedRoute>
    </Switch>
  );
}

export default withRoot(App);
