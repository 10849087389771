import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import Slider from '../../components/Slider/Slider';
import Stores from '../../components/Stores/Stores';
import CouponsSection from '../../components/CouponsSection/CouponsSection';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('home')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <Slider />
      <Stores />
      <CouponsSection />
    </>
  );
};

export default Home;
