import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Heading from '../../components/Heading/Heading';
import ErrorInput from '../../components/ErrorInput/ErrorInput';
import Modal from '../../components/Modal/Modal';

import { forget_password } from '../../apis/Qasimati';

import useStyles from './styles';

const ForgetPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lng = useSelector((state) => state.lang);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(t('email_required'))
        .email(t('email_invalid')),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { data } = await forget_password({ email: values.email, lng });
        setLoading(false);
        setText(data.message);
      } catch (err) {
        setText(err.message);
      }
    },
  });

  useEffect(() => {
    console.log(text);
    if (text) {
      setOpen(true);
    }
  }, [text]);

  useEffect(() => {
    if (!open) setText('');
  }, [open]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('forget_password')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('forget_password')} />
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={t('email')}
              name='email'
              variant='filled'
              size='small'
              required
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <ErrorInput text={formik.errors.email} />
          </FormControl>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
          >
            {t('send')}
          </Button>
        </form>
      </div>
      <Modal open={open} setOpen={setOpen} text={text} />
    </>
  );
};

export default ForgetPassword;
