import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: 0,
    display: 'inline-block',
    textDecoration: 'none',
  },
  text: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));
