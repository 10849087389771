import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import PublicIcon from '@material-ui/icons/Public';
import CheckIcon from '@material-ui/icons/Check';

import { change_country } from '../../../redux/actions/country';

import { get_countries } from '../../../apis/Qasimati';

import useStyles from './styles';

const Countries = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lng = useSelector((state) => state.lang);
  const countryShorcut = useSelector((state) => state.country);

  const [anchorEl, setAnchorEl] = useState(null);
  const [countries, setCountries] = useState([]);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await get_countries(lng);
        setCountries(data.data);
      } catch (err) {}
    };
    fetchCountries();
  }, [lng]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (shortcut) => {
    dispatch(change_country(shortcut));
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {countries.map((country, index) => (
        <MenuItem
          key={country.id}
          onClick={() => handleMenuClick(country.shortcut)}
        >
          <ListItemIcon className={classes.check}>
            {countryShorcut === country.shortcut && (
              <CheckIcon fontSize='small' color='primary' />
            )}
          </ListItemIcon>

          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <img alt='country' src={country.image} className={classes.img} />
            </Grid>
            <Grid item>
              <Typography variant='body1'>{country[`name`]}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <IconButton
        edge='end'
        aria-label='account of current user'
        aria-controls={menuId}
        aria-haspopup='true'
        onClick={handleProfileMenuOpen}
        color='inherit'
      >
        <PublicIcon />
      </IconButton>
      {renderMenu}
    </>
  );
};

export default Countries;
