import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

// Icons
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '../Container/Container';
import SearchResult from '../SearchResult/SearchResult';
import Logo from '../Logo/Logo';
import VerificationBar from '../VerificationBar/VerificationBar';
import CountriesMenu from './Countries/Countries';

import { logout } from '../../redux/actions/auth';

import { get_stores } from '../../apis/Qasimati';

import useStyles from './styles';

const Header = ({ changeLanguage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);
  const lng = useSelector((state) => state.lang);
  const country = useSelector((state) => state.country);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const fetchStoresAccordingToKeyword = async () => {
      setLoading(true);

      try {
        const { data } = await get_stores({
          country,
          lng,
          keyword,
        });
        setStores(data.data);
        setLoading(false);
        setIsSearch(true);
      } catch (err) {
        setLoading(false);
      }
    };
    const delayId = setTimeout(() => {
      if (keyword) fetchStoresAccordingToKeyword();
      else setIsSearch(false);
    }, 500);

    return () => {
      clearTimeout(delayId);
      setStores([]);
    };
  }, [keyword, country, lng]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };

  const handleClickedItem = (name) => {
    setKeyword('');
    setStores('');
    setIsSearch(false);
    history.push(`/stores/${name}`);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {user?.name && (
        <MenuItem>
          <Button
            color='inherit'
            className={classes.headerLinks}
            component={Link}
            to='/profile'
          >
            {t('profile')}
          </Button>
        </MenuItem>
      )}

      {user?.name && (
        <MenuItem>
          <Button
            color='inherit'
            component={Link}
            to='/favorite'
            className={classes.headerLinks}
          >
            {t('favorite')}
          </Button>
        </MenuItem>
      )}

      <MenuItem>
        {user?.name ? (
          <Button
            color='inherit'
            className={classes.headerLinks}
            onClick={handleLogout}
          >
            {t('logout')}
          </Button>
        ) : (
          <Button
            color='inherit'
            component={Link}
            to='/login'
            className={classes.headerLinks}
          >
            {t('login')}
          </Button>
        )}
      </MenuItem>

      <MenuItem>
        <Button
          onClick={() => changeLanguage()}
          color='inherit'
          className={classes.headerLinks}
        >
          {t('lang')}
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.grow}>
        {user?.name && !user?.verified_at && <VerificationBar />}
        <AppBar position='static'>
          <Container>
            <Toolbar disableGutters>
              <Logo />
              <div className={classes.search} dir={i18n.dir()}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder={t('search')}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                {loading && (
                  <CircularProgress className={classes.loader} size='17px' />
                )}
                {isSearch && (
                  <SearchResult
                    stores={stores}
                    handleClickedItem={handleClickedItem}
                  />
                )}
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {user?.name && (
                  <Button
                    color='inherit'
                    className={classes.headerLinks}
                    component={Link}
                    to='/profile'
                  >
                    {t('profile')}
                  </Button>
                )}

                {user?.name && (
                  <Button
                    color='inherit'
                    component={Link}
                    to='/favorite'
                    className={classes.headerLinks}
                  >
                    {t('favorite')}
                  </Button>
                )}

                {user?.name ? (
                  <Button
                    color='inherit'
                    className={classes.headerLinks}
                    onClick={handleLogout}
                  >
                    {t('logout')}
                  </Button>
                ) : (
                  <Button
                    color='inherit'
                    component={Link}
                    to='/login'
                    className={classes.headerLinks}
                  >
                    {t('login')}
                  </Button>
                )}

                <Button
                  onClick={() => changeLanguage()}
                  color='inherit'
                  className={classes.headerLinks}
                >
                  {t('lang')}
                </Button>

                <CountriesMenu />
              </div>
              <div className={classes.sectionMobile}>
                <CountriesMenu />
                <IconButton
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  onClick={handleMobileMenuOpen}
                  color='inherit'
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
        {renderMobileMenu}
      </div>
    </>
  );
};

export default Header;
