import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    outline: 'none',
    maxWidth: 450,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
    borderRadius: 20,
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 3,
    '&:hover': {
      background: 'none',
    },
  },
  divide: {
    marginBottom: 30,
  },
}));
