import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginBottom: 25,
    paddingInlineStart: 20,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      background: theme.palette.primary.main,
      borderRadius: 8,
      width: 5,
      height: '100%',
      position: 'absolute',
      left: 0,
    },
  },
}));
