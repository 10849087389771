const intialState = localStorage.getItem('lng')
  ? localStorage.getItem('lng')
  : 'ar';

const langReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'LANG':
      return action.payload;

    default:
      return state;
  }
};

export default langReducer;
