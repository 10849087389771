import { Link } from 'react-router-dom';

// Material-UI Components
import ListItemText from '@material-ui/core/ListItemText';

// Custom Componenets
import useStyles from './styles';

const CustomListItem = ({ text, link }) => {
  const classes = useStyles();

  return (
    <div>
      <Link to={link} className={classes.root}>
        <ListItemText primary={text} className={classes.text} />
      </Link>
    </div>
  );
};

export default CustomListItem;
