import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginTop: 20,
    height: 330,
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
    [theme.breakpoints.down('xs')]: {
      height: 180,
    },
  },
  img: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
}));
