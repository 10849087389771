import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import Heading from '../../components/Heading/Heading';
import CouponModal from '../../components/CouponModal/CouponModal';
import Coupon from '../../components/Coupon/Coupon';
import Pagination from '../../components/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';

import { get_favorite } from '../../apis/Qasimati';

import useStyles from './styles';
import { Helmet } from 'react-helmet';

const Favorite = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const lng = useSelector((state) => state.lang);

  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    const fetchFav = async () => {
      try {
        const { data } = await get_favorite({ page, lng });
        setCoupons(data.data);
        setPages(Math.ceil(data.meta.total / data.meta.per_page));
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchFav();
  }, [lng, page]);

  const handleSelectedCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    setOpen(true);
  };

  const handleLikedCoupon = (id, enable) => {
    setCoupons(
      coupons.map((coupon) =>
        coupon.id === id ? { ...coupon, enable } : coupon
      )
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('favorite')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <section className={classes.root}>
        <Heading text={t('favorite')} />

        <Grid container spacing={3}>
          {loading ? (
            <Grid item xs={12}>
              <Loader />
            </Grid>
          ) : coupons.length === 0 ? (
            <Grid item xs={12}>
              {t('fav_empty')}
            </Grid>
          ) : (
            coupons.map((coupon) => (
              <Grid key={coupon.id} item xs={6} sm={6} md={4} lg={3}>
                <Coupon
                  setSelectedCoupon={handleSelectedCoupon}
                  coupon={coupon}
                  lng={lng}
                />
              </Grid>
            ))
          )}

          {!loading && coupons.length > 0 && (
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Pagination pages={pages} page={page} setPage={setPage} />
            </Grid>
          )}
        </Grid>
        {selectedCoupon && (
          <CouponModal
            open={open}
            setOpen={setOpen}
            coupon={selectedCoupon}
            lng={lng}
            handleLikedCoupon={handleLikedCoupon}
          />
        )}
      </section>
    </>
  );
};

export default Favorite;
