import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Material-UI Components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

// Custom Componenets
import Container from '../Container/Container';
import ListItem from '../ListItem/ListItem';
import Modal from '../Modal/Modal';

// Icons
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';
import InstagramIcon from '@material-ui/icons/Instagram';

import Logo from '../Logo/Logo';
//import apple from '../../assets/images/applestore.svg';
import google from '../../assets/images/googleplay.svg';

import { get_stores, get_social_links } from '../../apis/Qasimati';

// Styles
import useStyles from './styles';

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [stores, setStores] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);
  const [open, setOpen] = useState(false);

  const user = useSelector((state) => state.user);
  const country = useSelector((state) => state.country);
  const lng = useSelector((state) => state.lang);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const { data } = await get_stores({ page: 1, country, lng });
        setStores(data.data.slice(0, 7));
      } catch {}
    };

    fetchStores();
  }, [country, lng]);

  useEffect(() => {
    const fetchSocial = async () => {
      try {
        const { data } = await get_social_links();
        setSocialLinks(data.social);
      } catch {}
    };

    fetchSocial();
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <footer className={classes.root}>
      <div className={classes.topFooter}>
        <Container>
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12} md={4}>
              <div className={classes.identification}>
                <div className={classes.logo}>
                  <Logo />
                </div>
                <Typography variant='body1' className={classes.idenText}>
                  {t('help')}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6} md={2}>
              <div className={classes.information}>
                <Typography variant='h6'>{t('information')}</Typography>
                <List component='nav'>
                  <ListItem text={t('about_us')} link={`/about-us`} />
                  <ListItem
                    text={t('privacy_policy')}
                    link={`/privacy-policy`}
                  />
                  <ListItem
                    text={t('terms_and_conditions')}
                    link={`/terms-conditions`}
                  />
                  <ListItem text={t('common_questions')} link={`/fqa`} />
                  <ListItem text={t('contact_us')} link={`/contact-us`} />

                  {user?.verified_at ? (
                    <ListItem text={t('add_coupon')} link={`/add-coupon`} />
                  ) : (
                    <Button className={classes.addCoupon} onClick={handleClick}>
                      {t('add_coupon')}
                    </Button>
                  )}
                </List>
              </div>
            </Grid>

            <Grid item xs={6} md={3}>
              <div className={classes.stores}>
                <Typography variant='h6'>{t('store_and_offers')}</Typography>
                <List component='nav'>
                  {stores.map((store) => (
                    <ListItem
                      key={store.id}
                      text={
                        lng === 'en'
                          ? `${store.name} ${t('footer_code')}`
                          : `${t('footer_code')} ${store.name}`
                      }
                      link={`/stores/${store.name}`}
                    />
                  ))}
                </List>
              </div>
            </Grid>

            <Grid item xs={6} md={3}>
              <div className={classes.ad}>
                <Typography variant='h6'>{t('contact_with_us')}</Typography>
                <ul className={classes.social}>
                  <li>
                    <a
                      href={socialLinks.twitter}
                      className={classes.socialIcon}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <TwitterIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialLinks.facebook}
                      className={classes.socialIcon}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FacebookIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialLinks.telegram}
                      className={classes.socialIcon}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <TelegramIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href={socialLinks.instagram}
                      className={classes.socialIcon}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                </ul>
                {/* <div className={classes.apple}>
                  <a href='/' target='_blank' rel='noreferrer'>
                    <img src={apple} alt='apple' className={classes.download} />
                  </a>
                </div> */}
                <div className={classes.google}>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.qasimati'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={google}
                      alt='google'
                      className={classes.download}
                    />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.copyright}>{t('copyright')}</div>
      <Modal
        open={open}
        setOpen={setOpen}
        text={!user?.name ? t('add_coupon_login') : t('add_coupon_verified')}
      />
    </footer>
  );
};

export default Footer;
