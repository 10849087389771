import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    outline: 'none',
    maxWidth: 450,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
    borderRadius: 20,
  },
  img: {
    width: '100%',
    objectFit: 'cover',
  },
  actions: {
    marginTop: 250,
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 3,
    '&:hover': {
      background: 'none',
    },
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    boxShadow: theme.shadows[3],
    marginBottom: 10,
  },
  divide: {
    marginBottom: 20,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  title2: {
    textAlign: 'center',
  },
  value: {
    textAlign: 'center',
    color: 'gray',
    padding: '0px 70px',
    height: 50,
    overflowY: 'auto',
  },
  span: {
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  likeActions: {},
  likesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  likeActionIcon: {
    padding: 15,
    cursor: 'pointer',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  chip: {
    cursor: 'pointer',
  },
}));
