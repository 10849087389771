import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import Heading from '../../components/Heading/Heading';
import Avatar from '../../components/AvatarC/AvatarC';
import Snackbar from '../../components/SnackbarC/SnackbarC';
import ErrorInput from '../../components/ErrorInput/ErrorInput';

import { editProfile, editProfileCleanup } from '../../redux/actions/auth';

import useStyles from './styles';
import { Helmet } from 'react-helmet';

const Profile = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    return () => dispatch(editProfileCleanup());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: user.name,
      newPassword: '',
      avatar: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('name_required')),
      newPassword: Yup.string().min(6, t('password_invalid')),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      const profileData = new FormData();
      profileData.append('name', values.name);
      if (values.newPassword)
        profileData.append('password', values.newPassword);
      if (values.avatar) profileData.append('image', values.avatar);
      dispatch(editProfile(profileData));
    },
  });

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('profile')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('profile')} />

        <form onSubmit={formik.handleSubmit}>
          {user?.profile_success && (
            <Alert
              severity={user?.profile_success === 'false' ? 'error' : 'success'}
              style={{ margin: 10 }}
            >
              {user?.profile_success === 'false'
                ? t('profile_updated_error')
                : t('profile_updated_successfully')}
            </Alert>
          )}

          <Grid container justifyContent='center'>
            <Grid item xs={12} md={8} lg={6}>
              <Avatar name='avatar' onAvatarChange={formik.setFieldValue} />

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  label={t('profile_name')}
                  variant='filled'
                  size='small'
                  name='name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {<ErrorInput text={formik.errors.name} />}
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  label={t('email')}
                  variant='filled'
                  size='small'
                  name='email'
                  value={user.email}
                  disabled
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <TextField
                  label={t('profile_new_password')}
                  variant='filled'
                  size='small'
                  name='newPassword'
                  type='password'
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  placeholder='*********'
                />
                {<ErrorInput text={formik.errors.newPassword} />}
              </FormControl>

              <Button variant='text' onClick={() => history.push('/')}>
                {t('cancel')}
              </Button>

              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={user?.loading}
              >
                {user?.loading ? t('wait') : t('profile_save')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={open} setOpen={setOpen} message='asdasda' />
      </div>
    </>
  );
};

export default Profile;
