import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    padding: 50,
  },
  header: {
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: 50,
  },
  formField: {
    marginBottom: 30,
  },
  formFieldHeader: {
    marginBottom: 10,
  },
  addConditions: {
    marginBottom: 30,
  },
  uploadImage: {
    display: 'flex',
    border: `1px dashed ${theme.palette.primary.main}`,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    cursor: 'pointer',
  },
  uploadText: {
    paddingInlineStart: 10,
  },
  imgPreviewWrap: {
    marginTop: 10,
    position: 'relative',
    height: 100,
  },
  imgPreview: {
    width: '100%',
    height: '100%',
  },
  imgPreviewClose: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
}));
