import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

import { add_to_favorite, remove_from_favorite } from '../../apis/Qasimati';

import useStyles from './styles';

const Coupon = ({ setSelectedCoupon, setOpenNote, coupon }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);

  const [isFav, setIsFav] = useState(coupon.is_favaiort === 'true');

  const handleShow = () => {
    setSelectedCoupon(coupon);
  };

  const handleAddToFav = async () => {
    setIsFav(!isFav);
    // make a request

    try {
      if (!isFav) return await add_to_favorite(coupon.id);
      return await remove_from_favorite(coupon.id);
    } catch (err) {}
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={coupon.tag.name}
        action={
          !user?.name ? (
            <IconButton aria-label='star' onClick={() => setOpenNote(true)}>
              <StarBorderIcon />
            </IconButton>
          ) : (
            <IconButton aria-label='star' onClick={() => handleAddToFav()}>
              {isFav ? (
                <StarIcon className={classes.star} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          )
        }
        style={{
          flexDirection: 'row-reverse',
          alignItems: 'center',
          paddingBottom: 0,
        }}
      />
      <CardMedia
        className={classes.media}
        image={coupon.store.image}
        title='store'
      />
      <CardContent className={classes.body}>
        <Typography
          variant='body1'
          component='p'
          className={classes.description}
        >
          {coupon[`main_title`]}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        {coupon.type === 'coupon' ? (
          <Button
            color='primary'
            variant='contained'
            className={classes.btn}
            onClick={handleShow}
          >
            {t('show_coupon')}
          </Button>
        ) : (
          <Link
            href={coupon.store.link}
            target='_blank'
            className={classes.offer}
          >
            <Button color='primary' variant='contained' className={classes.btn}>
              {t('go_offer')}
            </Button>
          </Link>
        )}
      </CardActions>
    </Card>
  );
};

export default Coupon;
