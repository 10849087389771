import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 150,
      paddingRight: 150,
    },
    minHeight: '50vh',
  },

  switcher: {
    marginBottom: 20,
  },
  formControl: {
    marginBottom: 20,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
