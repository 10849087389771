import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import FileInput from '../../components/FileInput/FileInput';
import Heading from '../../components/Heading/Heading';
import ErrorInput from '../../components/ErrorInput/ErrorInput';

import { get_countries, add_coupon } from '../../apis/Qasimati';

import useStyles from './styles';
import { Helmet } from 'react-helmet';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddCoupon = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const lng = useSelector((state) => state.lang);
  const user = useSelector((state) => state.user);

  const [countriesAPI, setCountries] = useState([]);
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await get_countries(lng);
        setCountries(data.data);
      } catch (err) {}
    };

    fetchCountries();
  }, [lng]);

  const formik = useFormik({
    initialValues: {
      storeName: '',
      storeLink: '',
      storeImage: null,
      countries: [],
      coupon: '',
      offerType: 'coupon',
      offerDetails: '',
      offerLink: '',
      startDate: '',
      endDate: '',
    },
    validationSchema: Yup.object().shape({
      storeName: Yup.string().required(t('store_name_required')),
      storeLink: Yup.string().required(t('store_link_required')),
      storeImage: Yup.mixed()
        //   .required(t('store_image_required'))
        .test('fileType', t('store_image_type_invalid'), (value) =>
          ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
            value?.type
          )
        ),
      countries: Yup.array().min(1, t('countries_required')),
      coupon: Yup.string().when('offerType', {
        is: 'coupon',
        then: Yup.string().required(t('coupon_required')),
        otherwise: Yup.string().notRequired(),
      }),
      offerLink: Yup.string().when('offerType', {
        is: 'offer',
        then: Yup.string().required(t('offer_link_required')),
        otherwise: Yup.string().notRequired(),
      }),
      offerType: Yup.string().required(t('offer_type_required')),
    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);

      const formData = new FormData();
      formData.append('name_store', values.storeName);
      formData.append('link_store', values.storeLink);
      formData.append('url_store', values.storeImage);
      formData.append(
        'countries',
        JSON.stringify(values.countries.map((country) => country.id))
      );
      values.offerType === 'coupon'
        ? formData.append('code', values.coupon)
        : formData.append('link', values.offerLink);
      formData.append('type', values.offerType);
      formData.append('title_coupon', values.offerDetails);
      formData.append('start_date', values.startDate);
      formData.append('end_date', values.endDate);
      formData.append('user_id', user.id);

      try {
        const response = await add_coupon(formData);

        if (response.status === 200) {
          setLoading(false);
          history.push('/');
        }
      } catch {
        setLoading(false);
      }
    },
  });

  const handleClosePreview = () => {
    setImageURL('');
    formik.setFieldValue('storeImage', null);
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('add_coupon')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('add_coupon')} />

        <Paper className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.formField}>
              <Typography variant='h5' className={classes.formFieldHeader}>
                {t('store_details')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      label={t('store_name')}
                      variant='filled'
                      size='small'
                      name='storeName'
                      value={formik.values.storeName}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                  {<ErrorInput text={formik.errors.storeName} />}
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      label={t('store_link')}
                      variant='filled'
                      size='small'
                      name='storeLink'
                      value={formik.values.storeLink}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                  {<ErrorInput text={formik.errors.storeLink} />}
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <label className={classes.uploadImage} htmlFor='storeImg'>
                      {formik.values.storeImage ? (
                        <CheckOutlinedIcon fontSize='large' color='primary' />
                      ) : (
                        <CloudUploadOutlinedIcon
                          fontSize='large'
                          color='primary'
                        />
                      )}
                      <Typography variant='h6' className={classes.uploadText}>
                        {formik.values.storeImage
                          ? t('upload_done')
                          : t('upload_text')}
                      </Typography>
                    </label>
                    <FileInput
                      name='storeImage'
                      onImageChange={formik.setFieldValue}
                      setImageURL={setImageURL}
                      id='storeImg'
                      label='storeImg'
                    />
                    {imageURL && (
                      <div className={classes.imgPreviewWrap}>
                        <img
                          src={imageURL}
                          alt='preview'
                          className={classes.imgPreview}
                        />
                        <IconButton
                          className={classes.imgPreviewClose}
                          onClick={handleClosePreview}
                          size='small'
                        >
                          <CloseOutlinedIcon fontSize='small' color='inherit' />
                        </IconButton>
                      </div>
                    )}
                    {<ErrorInput text={formik.errors.storeImage} />}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id='demo-mutiple-chip-label'>
                      {t('store_countries')}
                    </InputLabel>
                    <Select
                      labelId='demo-mutiple-chip-label'
                      id='demo-mutiple-chip'
                      multiple
                      variant='filled'
                      name='countries'
                      value={formik.values.countries}
                      onChange={formik.handleChange}
                      input={<Input id='select-multiple-chip' />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((country) => (
                            <Chip
                              key={country.id}
                              label={country.name}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {countriesAPI.map((country) => (
                        <MenuItem
                          key={country.id}
                          value={country}
                          style={getStyles(
                            country.name,
                            formik.values.countries,
                            theme
                          )}
                        >
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {<ErrorInput text={formik.errors.countries} />}
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className={classes.formField}>
              <Typography variant='h5' className={classes.formFieldHeader}>
                {t('coupon_details')}
              </Typography>
              <Grid container spacing={2}>
                {formik.values.offerType === 'coupon' ? (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        label={t('coupon')}
                        variant='filled'
                        size='small'
                        name='coupon'
                        value={formik.values.coupon}
                        onChange={formik.handleChange}
                      />
                      {<ErrorInput text={formik.errors.coupon} />}
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        label={t('offer_link')}
                        variant='filled'
                        size='small'
                        name='offerLink'
                        value={formik.values.offerLink}
                        onChange={formik.handleChange}
                      />
                      {<ErrorInput text={formik.errors.offerLink} />}
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant='filled'
                    size='small'
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id='demo-simple-select-filled-label'>
                      {t('offer_type')}
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-filled-label'
                      id='demo-simple-select-filled'
                      name='offerType'
                      value={formik.values.offerType}
                      onChange={(e) => {
                        formik.setFieldValue('coupon', '');
                        formik.setFieldValue('offerType', e.target.value);
                      }}
                    >
                      <MenuItem value=''>
                        <em>{t('offer_type')}</em>
                      </MenuItem>
                      <MenuItem value='coupon'>{t('coupon_select')}</MenuItem>
                      <MenuItem value='offer'>{t('offer_select')}</MenuItem>
                    </Select>
                    {<ErrorInput text={formik.errors.offerType} />}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      label={t('start_date')}
                      variant='filled'
                      size='small'
                      type='date'
                      name='startDate'
                      value={formik.values.startDate}
                      onChange={formik.handleChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      label={t('end_date')}
                      variant='filled'
                      size='small'
                      type='date'
                      name='endDate'
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      label={t('offer_details')}
                      variant='filled'
                      size='small'
                      name='offerDetails'
                      value={formik.values.offerDetails}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className={classes.addConditions}>
              <Typography variant='h5'>{t('add_conditions')}</Typography>
              <List component='nav'>
                <ListItem>
                  - &nbsp; <ListItemText primary={t('condition_one')} />
                </ListItem>
                <ListItem>
                  - &nbsp;
                  <ListItemText primary={t('condition_two')} />
                </ListItem>
                <ListItem>
                  - &nbsp; <ListItemText primary={t('condition_three')} />
                </ListItem>
              </List>
            </div>

            <Grid container spacing={2}>
              <Grid item>
                <Button variant='text' onClick={() => history.push('/')}>
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={loading}
                >
                  {loading ? t('wait') : t('add')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  );
};

export default AddCoupon;
