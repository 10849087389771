import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import Heading from '../../components/Heading/Heading';
// import Pagination from '../../components/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';

import { get_stores, get_feature_stores } from '../../apis/Qasimati';

import useStyles from './styles';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
const Stores = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lng = useSelector((state) => state.lang);
  const country = useSelector((state) => state.country);

  const [stores, setStores] = useState([]);
  const [popularStores, setPopularStores] = useState([]);
  //const [pages, setPages] = useState(1);
  const [page] = useState(1);
  const [popularLoading, setPopularLoading] = useState('false');
  const [storesLoading, setStoresLoading] = useState('false');

  useEffect(() => {
    setStoresLoading(true);
    const fetchStores = async () => {
      try {
        const { data } = await get_stores({ page, country, lng });
        setStores(data.data);
        //setPages(Math.ceil(data.meta.total / data.meta.per_page));
        setStoresLoading(false);
      } catch (err) {
        setStoresLoading(false);
      }
    };
    fetchStores();
  }, [page, country, lng]);

  useEffect(() => {
    const fetchPopularStores = async () => {
      setPopularLoading(true);
      try {
        const { data } = await get_feature_stores({ country, lng });
        setPopularStores(data.data);
        setPopularLoading(false);
      } catch (err) {
        setPopularLoading(false);
      }
    };
    fetchPopularStores();
  }, [country, lng]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('all_stores')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        {popularLoading && storesLoading ? (
          <Loader />
        ) : (
          <>
            {popularStores.length > 0 && (
              <>
                <Heading text={t('popular_stores')} />
                <Grid container spacing={3} className={classes.popular_stores}>
                  {popularStores.map((store) => (
                    <Grid key={store.id} item>
                      <Link
                        to={`/stores/${store.name}`}
                        className={classes.store}
                      >
                        <Avatar
                          className={classes.avatar}
                          src={store.image}
                          imgProps={{ style: { objectFit: 'contain' } }}
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            <Heading text={t('all_stores')} />
            <Grid container spacing={3}>
              {stores.map((store) => (
                <Grid key={store.id} item>
                  <Link to={`/stores/${store.name}`} className={classes.store}>
                    <Avatar
                      className={classes.avatar}
                      src={store.image}
                      imgProps={{ style: { objectFit: 'contain' } }}
                    />
                  </Link>
                </Grid>
              ))}

              {/* <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Pagination pages={pages} page={page} setPage={setPage} />
              </Grid> */}
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default Stores;
