import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Filter from '../Filter/Filter';
import useStyles from './styles';
import Coupon from '../Coupon/Coupon';
import Pagination from '../Pagination/Pagination';
import CouponModal from '../CouponModal/CouponModal';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';

import {
  get_coupons,
  get_coupons_by_category,
  coupon_filteration,
  offer_filteration,
} from '../../apis/Qasimati';

const CouponsSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const lng = useSelector((state) => state.lang);
  const country = useSelector((state) => state.country);

  const [coupons, setCoupons] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [type, setType] = useState('all');
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [open, setOpen] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      try {
        if (!selectedCategory) {
          let data;
          if (type === 'coupon') {
            const response = await coupon_filteration({ page, country, lng });
            data = response.data;
          } else if (type === 'offer') {
            const response = await offer_filteration({ page, country, lng });
            data = response.data;
          } else {
            const response = await get_coupons({ page, country, lng });
            data = response.data;
          }

          setCoupons(data.data);
          setPages(Math.ceil(data.meta.total / data.meta.per_page));
        } else {
          const { data } = await get_coupons_by_category({
            category: selectedCategory,
            country,
            lng,
            type: type === 'all' ? '' : type,
          });
          setCoupons(data.coupons);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    fetchCoupons();
  }, [page, lng, country, selectedCategory, type]);

  const handleSelectedCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    setOpen(true);
  };

  const handleLikedCoupon = (id, enable) => {
    setCoupons(
      coupons.map((coupon) =>
        coupon.id === id ? { ...coupon, enable } : coupon
      )
    );
  };

  return (
    <section className={classes.root}>
      <Typography variant='h3' color='primary'>
        {t('offers')}
      </Typography>

      {/* Component */}
      <div className={classes.bar}></div>

      {/* Filter */}
      <Filter
        setSelectedCategory={setSelectedCategory}
        type={type}
        setType={setType}
        setPage={setPage}
      />

      <Grid container spacing={3}>
        {loading ? (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        ) : (
          coupons.map((coupon) => (
            <Grid key={coupon.id} item xs={6} sm={6} md={4} lg={3}>
              <Coupon
                setSelectedCoupon={handleSelectedCoupon}
                setOpenNote={setOpenNote}
                coupon={coupon}
              />
            </Grid>
          ))
        )}
        {!selectedCategory && (
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Pagination pages={pages} page={page} setPage={setPage} />
          </Grid>
        )}
      </Grid>
      {selectedCoupon && (
        <CouponModal
          open={open}
          setOpen={setOpen}
          coupon={selectedCoupon}
          lng={lng}
          handleLikedCoupon={handleLikedCoupon}
        />
      )}

      <Modal
        open={openNote}
        setOpen={setOpenNote}
        text={t('add_fav_tooltip')}
      />
    </section>
  );
};

export default CouponsSection;
