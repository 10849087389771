import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    color: '#333',
    borderRadius: 8,
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 0,
    paddingTop: '40.25%', // 16:9
    backgroundSize: 'contain',
  },
  body: {
    height: 38,
    overflow: 'hidden',
  },
  description: {
    fontWeight: 500,
  },
  actions: {
    justifyContent: 'center',
    marginBottom: 5,
  },
  btn: {
    borderRadius: 8,
    fontSize: 15,
    textTransform: 'none',
  },
  exlusive: {
    textAlign: 'right',
    position: 'relative',
    top: -5,
  },
  star: {
    color: '#f9ca24',
  },

  offer: {
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
