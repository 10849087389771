import { useState } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import FileInput from '../FileInput/FileInput';

import useStyles from './styles';

const Avatar = ({ name, onAvatarChange }) => {
  const classes = useStyles();

  const user = useSelector((state) => state.user);

  const [imgURL, setImgURL] = useState(user.image);

  return (
    <div
      className={classes.avatar}
      style={{
        background: `url(${imgURL && imgURL})`,
      }}
    >
      <>
        <label htmlFor='avatar' className={classes.label}>
          <AddIcon />
        </label>
        <FileInput
          name={name}
          onImageChange={onAvatarChange}
          setImageURL={setImgURL}
          id='avatar'
        />
      </>

      {!imgURL && (
        <PermIdentityIcon className={classes.icon} fontSize='large' />
      )}
    </div>
  );
};

export default Avatar;
