import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';

const Loader = ({ size }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress color='primary' size={size} />
    </div>
  );
};

export default Loader;
