import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal/Modal';

import { verify_email } from '../../apis/Qasimati';

import useStyles from './styles';

const VerificationBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    try {
      const response = await verify_email();
      if (response.status === 200) setOpen(true);
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      {t('verification_message')}
      <span className={classes.btn} onClick={handleClick}>
        {t('resend')}
      </span>
      {<CustomModal open={open} setOpen={setOpen} text={t('verify_message')} />}
    </div>
  );
};

export default VerificationBar;
