import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import Container from '../components/Container/Container';

import { change_lang } from '../redux/actions/lang';

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const theme = useTheme();

  const webview = queryString.parse(location.search)?.webview;

  document.body.dir = i18n.dir();

  const changeLanguage = () => {
    const lng = localStorage.getItem('lng') === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    document.getElementsByTagName('html')[0].setAttribute('lang', lng);
    dispatch(change_lang(lng));
    // const swiper = document.querySelectorAll('.swiper-container');

    // if (lng === 'en') {
    //   swiper.rtl = false;
    //   swiper.rtlTranslate = false;
    // } else {
    //   swiper.rtl = true;
    //   swiper.rtlTranslate = true;
    //   console.log(swiper.rtlTranslate);
    // }

    // swipers.map((swiper) => {
    //   console.log(swiper);
    // });
  };

  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('lang', localStorage.getItem('i18nextLng'));
  }, []);

  return (
    <>
      {!webview && <Header changeLanguage={changeLanguage} />}

      <main>
        <Container>{children}</Container>
      </main>

      {!webview && <Footer />}
    </>
  );
};

export default MainLayout;
