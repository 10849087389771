import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 150,
      paddingRight: 150,
    },
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formControl: {
    marginBottom: 20,
  },
}));
