import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    avatar: {
      position: 'relative',
      width: '80px',
      height: '80px',
      borderRadius: '50%',
      padding: '5px',
      border: `2px solid #bdc3c7`,
      margin: '0 auto',
      backgroundSize: 'cover !important',
      backgroundOrigin: 'border-box !important',
      backgroundRepeat: 'no-repeat !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    label: {
      cursor: 'pointer',
      display: 'flex',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      color: theme.palette.primary.main,
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#fff',
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
    icon: {
      color: theme.palette.primary.main,
    },
  };
});
