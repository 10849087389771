const intialState = localStorage.getItem('country')
  ? localStorage.getItem('country')
  : 'SA';

const langReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'COUNTRY':
      return action.payload;

    default:
      return state;
  }
};

export default langReducer;
