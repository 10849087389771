import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';

import { about_us } from '../../apis/Qasimati';

import useStyles from './styles';

const AboutUs = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const lng = useSelector((state) => state.lang);

  const [htmlData, setHtmlData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchHTML = async () => {
      try {
        const { data } = await about_us(lng);
        setHtmlData(data.value);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchHTML();
  }, [lng]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {t('title')}
          {t('about_us')}
        </title>
        <link rel='canonical' href='https://qasimati.com' />
      </Helmet>
      <div className={classes.root}>
        <Heading text={t('about_us')} />
        {loading ? (
          <Loader />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: htmlData }} />
        )}
      </div>
    </>
  );
};

export default AboutUs;
