import Typography from '@material-ui/core/Typography';

import useStyles from './styles';

const Heading = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant='h3'>{text}</Typography>
    </div>
  );
};

export default Heading;
